import $ from 'jquery'
import 'slick-carousel'
const $doc = $(document)

function startNewsSlider() {
    $('.js-content-carousel-news').slick({
        dots: true,
        fade: true,
        arrows: true,
        mobileFirst: true,
        prevArrow: '<div class="slick-prev"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.9735 19.0623L9.02643 12L14.9735 4.93768" stroke="white" stroke-width="1.5" /></svg></div>',
        nextArrow: '<div class="slick-next"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M9.02643 19.0623L14.9735 12L9.02643 4.93768" stroke="white" stroke-width="1.5"/></svg></div>',
        responsive: [
            {
                breakpoint: 960,
                settings: {
                    dots: true,
                    fade: false,
                }
            }
        ]
    });
}

function startCareersCarousel() {
    $('.js-image-carousel').slick({
        dots: false,
        arrows: false,
        mobileFirst: true,
        autoplay: true,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 960,
                settings: {
                    centerMode: true,
                }
            }
        ]
    });
}

function startHeroSlider() {
    $('.js-hero-slider').slick({
        dots: true,
        arrows: false,
        fade: true,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: false,
    });
}

function startFeaturedStoriesSlider() {
    $('.js-featured-stories').slick({
        dots: true,
        arrows: true,
        prevArrow: '<div class="slick-prev"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.9735 19.0623L9.02643 12L14.9735 4.93768" stroke="white" stroke-width="1.5" /></svg></div>',
        nextArrow: '<div class="slick-next"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M9.02643 19.0623L14.9735 12L9.02643 4.93768" stroke="white" stroke-width="1.5"/></svg></div>',
    });
}

function switchFeaturedStoriesSlider(){
    var dotId = $(this).data('slidedot');
    $('.js-featured-dots').removeClass('active');
    $(this).addClass('active');
    $("#"+dotId).click();
}

export default function initSlickSliders() {
    if ($('.js-content-carousel-news').length) startNewsSlider();
    if ($('.js-image-carousel').length) startCareersCarousel();
    if ($('.js-hero-slider').length) startHeroSlider();
    if ($('.js-featured-stories').length) {
        startFeaturedStoriesSlider();
        $('.js-featured-dots').eq(0).addClass('active');
        $doc.on('click', '.js-featured-dots', switchFeaturedStoriesSlider)
        $('.slick-arrow').on('click', function(){
            var activeDot = $(".js-featured-stories .slick-active button").attr("id");
            $('.js-featured-dots').removeClass('active');
            $('.js-featured-dots[data-slideDot=' + activeDot + ']').addClass('active');
        })
    }
}
