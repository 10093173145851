import $ from 'jquery'
const $doc = $(document)
var canLoadPartners = true;
var canLoadStories = true;

let $maxStoriesPages = $('#loadMoreStories').data('max-pages');

let $maxPartnersPages = $('#loadMorePartners').data('max-pages');

function showMorePartners(partners_page) {
    $('#loadMorePartners').hide();
    $.ajax({
        url: SITE.ajaxUrl,
        type: 'POST',
        data: {
            action: 'load_more_partners',
            partners_page: partners_page,
        },
        success: function (response) {
            if (response) {
                $('.partners-container').append(response);
                $('#loadMorePartners').show();
                partners_page++;
                $('#loadMorePartners').data('page', partners_page);
                if (partners_page > $maxPartnersPages) {
                    $('#loadMorePartners').hide();
                }
                //console.log('success '+stories_page);
            } else {
                canLoadPartners = false; // No more posts to load
                $('#loadMorePartners').hide();
                //console.log('fail ' + stories_page);
            }
        },
        error: function () {
            console.log('Error loading more posts.');
        }
    });
}

function filterPartners(integration_type, category_type, search_val) {
    $.ajax({
        url: SITE.ajaxUrl,
        type: 'POST',
        data: {
            action: 'filter_partners',
            integration_type: integration_type,
            category_type: category_type,
            search_val: search_val,
        },
        success: function (response) {
            $('.partners-container').html(response);
            $('#loadMorePartners').hide();
        },
        error: function () {
            console.log('Error loading more posts.');
        }
    });
}

function showMoreStories(stories_page) {
    $('#loadMoreStories').hide();
    $.ajax({
        url: SITE.ajaxUrl,
        type: 'POST',
        data: {
            action: 'load_more_stories',
            stories_page: stories_page,
        },
        success: function (response) {
            if (response) {
                $('.customer-stories-list').append(response);
                $('#loadMoreStories').show();
                stories_page++;
                $('#loadMoreStories').data('page', stories_page);
                if (stories_page > $maxStoriesPages ) {
                    $('#loadMoreStories').hide();
                }
                //console.log('success '+stories_page);
            } else {
                canLoadStories = false; // No more posts to load
                $('#loadMoreStories').hide();
                //console.log('fail ' + stories_page);
            }
        },
        error: function () {
            console.log('Error loading more story posts.');
        }
    });
}

function filterStories(story_type){
    $('.customer-stories-list').addClass('loading');
    $('.customer-story-box').hide();
    $('#loadMoreStories').hide();
    $.ajax({
        url: SITE.ajaxUrl,
        type: 'POST',
        data: {
            action: 'filter_stories',
            story_type: story_type,
        },
        success: function (response) {
            $('.customer-stories-list').html(response);
            $('.customer-stories-list').removeClass('loading');
            $('.customer-story-box').show();
        },
        error: function () {
            console.log('Error loading more posts.');
        }
    });
}

export default function initAjaxFunctions() {
    $(document).on("click", '#loadMorePartners', function (event) {
        var partners_page = $(this).data('page');
        showMorePartners(partners_page);
    });
    $(document).on("click", '#loadMoreStories', function (event) {
        var stories_page = $(this).data('page');
        showMoreStories(stories_page);
    });
    $doc.on('click', '.customer-story-landing__filters button', function () {
        $('.customer-story-landing__filters button').removeClass('active')
        var story_type = $(this).data('cat');
        filterStories(story_type);
        $(this).addClass('active');
    });

    $doc.on('change', '#story-type-select', function(){
        var story_type = $(this).val();
        filterStories(story_type);
    })

    $('#partners-list select').on('change', function () {
        var integration_type = $('#integration-type-select').val();
        var category_type = $('#category-type-select').val();
        var search_val = $('#partners-search').val();
        filterPartners(integration_type, category_type, search_val);
    });

    $('#partners-list input').on('input', function () {
        var integration_type = $('#integration-type-select').val();
        var category_type = $('#category-type-select').val();
        var search_val = $('#partners-search').val();
        filterPartners(integration_type, category_type, search_val);
    });
}
